import env from './env';

const resolvePath = (path: string) => {
  if (path === '/') return '';
  return path.endsWith('/') ? path.slice(0, path.length) : path;
};

export const pathWithBaseUrl = (path: string) =>
  `${resolvePath(env.PUBLIC_URL)}/${path}`;
export const pathWithChannel = (path: string, channel?: string) =>
  `${resolvePath(env.PUBLIC_URL)}/${channel ?? ':channel'}/${path}`;

export const ROUTES = {
  ROOT: pathWithBaseUrl('principal'),
  LANDING: pathWithBaseUrl('principal'),
  LANDING_MIRROW: pathWithBaseUrl('principal-experiencia'),
  LANDING_BEX: pathWithBaseUrl('principal-bex'),
  LANDING_BCP: pathWithBaseUrl('principal-bcp'),
  LANDING_AE: pathWithBaseUrl('autoefectivo-experiencia'),
  LANDING_MIRROR_AE: pathWithBaseUrl('autoefectivo-montofijo'),
  STEP2: pathWithBaseUrl('cotizacion'),
  STEP2_MIRROW: pathWithBaseUrl('cotizacion-experiencia'),
  STEP2_BEX: pathWithBaseUrl('cotizacion-bex'),
  STEP3: pathWithBaseUrl('resumen'),
  STEP3_MIRROW: pathWithBaseUrl('resumen-experiencia'),
  STEP3_BEX: pathWithBaseUrl('resumen-bex'),
  STEP4: pathWithBaseUrl('pago'),
  STEP5: pathWithBaseUrl('confirmacion'),
  STEP5_MIRROW: pathWithBaseUrl('confirmacion-experiencia'),
  STEP5_BEX: pathWithBaseUrl('confirmacion-bex'),
  NEWPLAN: pathWithBaseUrl('autoefectivo'),
  THANKS: pathWithBaseUrl('gracias'),
  QUOTATION_AE: pathWithBaseUrl('cotizacion-autoefectivo-experiencia'),
  QUOTATION_MIRROR_AE: pathWithBaseUrl('cotizacion-autoefectivo-montofijo'),
  PAYMENT_AE: pathWithBaseUrl('resumen-autoefectivo-experiencia'),
  PAYMENT_MIRROR_AE: pathWithBaseUrl('resumen-autoefectivo-montofijo'),
  THANKS_AE: pathWithBaseUrl('gracias-autoefectivo-experiencia'),
  CONFIRMATION: pathWithBaseUrl('confirmacion'),
  CONFIRMATION_AE: pathWithBaseUrl('confirmacion-autoefectivo-experiencia'),
  CONFIRMATION_MIRROR_AE: pathWithBaseUrl(
    'confirmacion-autoefectivo-montofijo',
  ),
  REMARKETING_PAYMENT_AE: pathWithBaseUrl('autoefectivo-cross'),
  REMARKETING_CONFIRMATION_AE: pathWithBaseUrl(
    'confirmacion-autoefectivo-cross',
  ),
  ERROR_PAYMENT: pathWithBaseUrl('error-payment'),
};

export default ROUTES;
